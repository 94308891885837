import React, { Component } from 'react';
import TileItemMixin from 'components/BaseEntities/TileItemMixin';


// Container

export default class ParticularProblemTile extends Component {

  render() {
    const { items, actions, loading, descriptions, meta } = this.props;
    let entities_class = "entities ex-tiles";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <ul className={entities_class}>
        {items.map(
          (child, i) =>
          <ParticularProblemTileItem key={i} data={child} actions={actions} descriptions={descriptions} position={i} meta={meta}/>
        )}
      </ul>
    );
  }
}

// Element

class ParticularProblemTileItem extends TileItemMixin(Component) {

  handleMouseOver(e) {
    const { data, actions, descriptions } = this.props,
          id = data.id;

    actions.showDescription(id);
    !descriptions[id] && actions.getEntityInfo(data);
  }

  handleMouseOut(e) {
    const { data, actions, descriptions } = this.props;
    actions.hideDescription(data.id);
  }

  getDescriptionBaloon(data, characteristics) {
    const created_at = new Date(data.extra.created_at),
      {state} = data.extra;
    let deadline = null;

    if (state == 'planned') {
      deadline = new Date(data.extra.postponed_until);
    } else if (data.extra.deadline) {
      deadline = new Date(data.extra.created_at);
      deadline.setDate(created_at.getDate() + data.extra.deadline);
    }

    let annotations = {};
    if (data.extra) {
      for (const [key, val] of Object.entries(data.extra)) {
        if (val instanceof Object && 'name' in val && 'value' in val)
          annotations[key] = val;
      }
    }

    if (characteristics.length) {
      return (
        <div className="ex-description-wrapper">
          <div className="ex-baloon">
            <div className="ex-arrow"/>
            <ul className="ex-attrs">
              {characteristics.map(
                (child, i) =>
                  <li data-path={child.path} key={i}
                    data-view-class={child.view_class.join(" ")}>
                    <strong>{child.name}:</strong>&nbsp;
                    {child.values.join("; ")}
                  </li>
              )}
              { deadline &&
                <li>
                  <strong>{gettext('Deadline')}:</strong>&nbsp;
                  <span>{deadline.getDate() > 9 ? deadline.getDate() : '0' + deadline.getDate()}.</span>
                  <span>{deadline.getMonth()+1 >= 9 ? deadline.getMonth() + 1 : '0' + (deadline.getMonth() + 1)}.</span>
                  <span>{deadline.getFullYear()}</span>
                  &nbsp;
                  <span>{deadline.getHours() > 9 ? deadline.getHours() : '0' + deadline.getHours()}:</span>
                  <span>{deadline.getMinutes() > 9 ? deadline.getMinutes() : '0' + deadline.getMinutes()}</span>
                </li> }
              {Object.keys(annotations).length !== 0 &&
                <li className="annotation">
                  {Object.keys(annotations).map(
                    (key, i) =>
                      <div key={i}>
                        <strong>{annotations[key].name}:&nbsp;</strong>
                        {annotations[key].value instanceof Array ?
                          annotations[key].value.map((val, key) => <span key={key}>{val};&nbsp;</span>)
                        :
                          <span key={key}>{annotations[key].value}</span>
                        }
                      </div>
                  )}
                </li>
              }
            </ul>
            <div className="particular-problem-extra">
              <hr/>
              <strong><i className="fa fa-calendar" aria-hidden="true"/></strong>&nbsp;
              {created_at.getDate() > 9 ? created_at.getDate() : '0'+created_at.getDate()}.
              {created_at.getMonth()+1 >= 9 ? created_at.getMonth()+1:'0'+(created_at.getMonth()+1)}.
              {created_at.getFullYear()}
              <strong className="second"><i className="fa fa-user-o" aria-hidden="true"/></strong>&nbsp;
                {data.extra.author}
            </div>
          </div>
        </div>
      )
    }
  }

  getItemBlock(descriptionBaloon, itemContent) {
    return(
      <div className="ex-catalog-item-block">
        {descriptionBaloon}
        {itemContent}
      </div>
    )
  }

  render() {
    const { data, descriptions, position, meta } = this.props,
          index = position + meta.offset;

    let liClass = "ex-catalog-item";
    if (descriptions.opened[data.id])
      liClass += " ex-state-description";

    let characteristics = data.short_characteristics || [],
        marks = data.short_marks || [];

    // let related_data_marts = [];
    if (descriptions[data.id]) {
        characteristics = descriptions[data.id].characteristics || [];
        marks = descriptions[data.id].marks || [];
        // related_data_marts = descriptions[data.id].marks || [];
    }

    const descriptionBaloon = this.getDescriptionBaloon(data, characteristics) || "",
          title = data.entity_name,
          itemContent = this.getItemContent(data, title, marks),
          itemBlock = this.getItemBlock(descriptionBaloon, itemContent);

    return(
      <li className={liClass}
          data-horizontal-position={this.state.h_pos}
          data-vertical-position="center"
          data-index={index}
          onMouseOver={e => { ::this.handleMouseOver(e) } }
          onMouseOut={e => { ::this.handleMouseOut(e) } }>
          {itemBlock}
      </li>
    );
  }
}

import React from 'react';
import marked from 'marked';
import { Doughnut, Pie } from "react-chartjs-2";
import EntityRatingMixin from "./EntityRatingMixin";


const SIZE_CHART = 110,
      STYLE_SIZE_CHART = Math.round(0.91 * SIZE_CHART),
      CHART_DOUGHNUT_OPTIONS = {
        legend: { display: false },
        tooltips: { enabled:false } ,
        layout:{ padding:70 },
        elements: {
          arc: { borderWidth: 0 }
        }
      },
      CHART_PIE_OPTIONS = {
        legend: { display: false },
        elements: {
          arc: { borderWidth: 0 }
        },
        responsive: true
      };

const EntityRatingTileItemMixin = Base => class extends EntityRatingMixin(Base) {

  toggleDescription(e) {
    const { data, actions, meta, descriptions } = this.props,
          id = data.id;

    if (this.getIsHover(e.clientX, e.clientY)) {
      if (this.reference && this.reference.chartInstance)
        this.reference.chartInstance.resize();

      actions.showDescription(id);

      if (data.extra.group_size && !meta.alike && !descriptions.groups[id])
        actions.getEntityInfo(data, meta);

      if (!data.extra.group_size && !descriptions[id])
        actions.getEntityInfo(data);

    } else
      actions.hideDescription(id);
  }

  getDescriptionBaloon(characteristics, itemData){
    const reference = {};
    const {annotations, created_at, author} = itemData;

    if (characteristics.length) {
      return (
        <div className="ex-description-wrapper">
          <div className="ex-baloon">
            <div className="ex-arrow"></div>
              {itemData.total  ?
              <>
                <div className="titleChart d-flex justify-content-center align-items-center">
                  <span>{gettext('Level of satisfaction')}</span>
                  <h2 className="d-flex justify-content-center align-items-center" style={{color:itemData.colorLevel}}>{itemData.percentage}<small style={{color:itemData.colorLevel}}> %</small></h2>
                </div>
                {itemData.measure &&
                  <div className="subTitleTile">
                    <p>{itemData.measure}</p>
                  </div>
                }
                <div className="chartPieContainer">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="chartPieComponent">
                      <Pie data={itemData.chartData} options={CHART_PIE_OPTIONS} width={STYLE_SIZE_CHART} height={STYLE_SIZE_CHART} ref = {reference => this.reference = reference}/>
                    </div>
                    <div className="chartPieLegend">
                      {itemData.chartData.labels.map((legend,i) =>
                        <p key={i}>
                          <span className="chartPieLegendColor" style={{background:itemData.chartData.datasets[0].backgroundColor[i]}}></span>
                          <span className="chartPieLegendText">{legend}: </span><span>{Number((itemData.chartData.datasets[0].data[i]/(itemData.total/100)).toFixed(2))}<small>%</small></span>
                        </p>
                      )}
                    </div>
                  </div>
                  {itemData.measures_count ?
                    <>
                      <span className="raitingText">{gettext('voted')}: </span>
                      <span>{Math.round(itemData.total/itemData.measures_count)}</span>
                    </>
                    :
                    <>
                      <span className="raitingText">Оценок: </span>
                      <span>{itemData.total}</span>
                    </>
                  }
                </div>
              </>
              : null}
              <ul className="ex-attrs">
              {characteristics.map(
                (child, i) =>
                  <li data-path={child.path} key={i}
                    data-view-class={child.view_class.join(" ")}>
                    <strong>{child.name}:&nbsp;</strong>
                    {child.values.join("; ")}
                  </li>
              )}
              {itemData.address ?
                  <li><strong>{gettext('address')}: </strong>{itemData.address}</li>
              : null}
              {Object.keys(annotations).length !== 0 &&
                <li>
                  {Object.keys(annotations).map(
                    (key, i) =>
                      <div key={i}>
                        <strong>{annotations[key].name}:&nbsp;</strong>
                        {annotations[key].value instanceof Array ?
                          annotations[key].value.map((val, key) => <span key={key}>{val};&nbsp;</span>)
                        :
                          <span key={key}>{annotations[key].value}</span>
                        }
                      </div>
                  )}
                </li>
              }
              </ul>
              {created_at ?
                  <div className="particular-initiative-extra">
                    <strong><i className="fa fa-calendar" aria-hidden="true"/></strong>&nbsp;
                    {created_at.getDate() > 9 ? created_at.getDate() : '0'+created_at.getDate()}.
                    {created_at.getMonth() + 1 > 9 ? created_at.getMonth() + 1:'0'+(created_at.getMonth() + 1)}.
                    {created_at.getFullYear()}
                    <strong className="second"><i className="fa fa-user-o" aria-hidden="true"/></strong>&nbsp;
                    {author}
                  </div>
                : null
              }
          </div>
        </div>
      )
    }
  }

  getItemBlock(groupSize, descriptionBaloon, groupDigit, itemContent){
    if (groupSize) {
      return(
        <div className="ex-catalog-item-block ex-catalog-raiting-item-block ex-catalog-item-variants"
           onClickCapture={e => {
               ::this.handleMouseClick(e);
           } }>
          <div>
              <div>
                  {descriptionBaloon}
                  {groupDigit}
                  {itemContent}
              </div>
          </div>
        </div>
      )
    } else {
      return(
        <div className="ex-catalog-item-block ex-catalog-raiting-item-block"
           onClickCapture={e => {
               ::this.handleMouseClick(e);
           }}>
          {descriptionBaloon}
          {itemContent}
        </div>
      )
    }
  }

  getItemContent(data, itemData, title, url, marks){
    return (
      <div className="ex-wrap-action">
        <div className="ex-media"
             dangerouslySetInnerHTML={{__html: marked(data.media, {sanitize: false})}}>
        </div>
        {itemData.total ?
        <div className="ex-ribbons-chart">
          <div className="chartDoughnut d-flex justify-content-center align-items-center">
            <Doughnut data={itemData.chartData} options={CHART_DOUGHNUT_OPTIONS} height={50} width={50}/>
            <span style={{color: itemData.colorLevel}}>{itemData.percentage}<small>%</small></span>
          </div>
        </div>: ""}
        <ul className="ex-ribbons">
          {marks.map(
            (child, i) =>
              <li className="ex-wrap-ribbon"
                  key={i}
                  data-name={child.name}
                  data-path={child.path}
                  data-view-class={child.view_class.join(" ")}>
                <div className="ex-ribbon">{child.values.join(", ")}</div>
              </li>
          )}
        </ul>
        <div className="ex-wrap-title">
          <h4 className="ex-title">
            <a href={url} title={title}>{title}</a>
          </h4>
        </div>
      </div>
    );
  }
};

export default EntityRatingTileItemMixin

import React from 'react';
import {withYMaps, YMaps} from 'react-yandex-maps';

import {YMapInner} from 'components/BaseEntities/YMap';
import {pieChartMapMixin} from './PieChartMapMixin';

import Color from 'color';


const opinions = {
  'yes': {
    text: 'Yes',
    color: "#32d332"
  },
  'rather_yes': {
    text: 'Rather yes',
    color: '#7CFC00'
  },
  'difficult_to_answer': {
    text: 'Difficult to answer',
    color: '#ffc40d'
  },
  'rather_no': {
    text: 'Rather no',
    color: '#ff6026'
  },
  'no': {
    text: 'No',
    color: '#e20007'
  }
};


function getVotingData(item) {
  const pollsResults = {},
    keys = Object.keys(opinions),
    prefix = item.extra.group_size ? 'sum_' : '';

  let measuresCount = 0;

  if (item.extra.group_size && !item.extra.sum_yes) {
    for (const k of keys)
      pollsResults[k] = 0;
  } else {
    for (const k of keys) {
      const field = item.extra[prefix + k];
      pollsResults[k] = item.extra.group_size ? field.value : field || 0;
    }
    measuresCount = item.extra.group_size ? item.extra.avg_measures_count.value : item.extra.measures_count;
  }

  const groupSize = item.extra.group_size || 1;

  let total = 0;
  for (const k of keys)
    total += pollsResults[k];

  let percentage = null;
  if (total) {
    percentage = 0;
    const pInc = 100 / (keys.length - 1);
    for (let i = 0; i < keys.length - 1; i++)
      percentage += pollsResults[keys[i]] / total * (100 - i * pInc);
    percentage = Math.floor(percentage);
  }

  let percentageArray = [];
  for (let i = 0; i < keys.length; i++)
    percentageArray[i] = pollsResults[keys[i]] / total;

  let color = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    keys.map((k, j) => {
      const colorStatus = Color(opinions[k].color).rgb().array();
      color[i] += Math.round(colorStatus[i] * percentageArray[j]);
    });
  }

  const voted = measuresCount ? Math.round(total / measuresCount) : 0,
    whiteness = 1 - Math.min(0.33 * voted / groupSize, 1);

  let pinColor, groupColor;

  if (total) {
    groupColor = Color.rgb(color).saturate(0.5);
    pinColor = Color(groupColor).lighten(whiteness * 0.9).hex();
  } else {
    groupColor = "#AAAAAA";
    pinColor = Color("#707070").lighten(whiteness * 0.9).hex();
  }

  const colorLevel = groupColor;

  groupColor = Color(groupColor).lighten(whiteness).alpha(0.9).rgb().string();

  const groupBorderColor = Color(groupColor).darken(0.35),
    regionColor = Color(groupColor).alpha(0.3).rgb().string();

  const data = {
    labels: keys.map((k) => gettext(opinions[k].text)),
    datasets: [
      {
        key: item.id,
        data: keys.map((k) => gettext(pollsResults[k])),
        backgroundColor: keys.map((k) => opinions[k].color),
      }
    ],
  };

  return {pinColor, groupColor, groupBorderColor, regionColor, colorLevel, data, percentage, total, voted};
}


function votingData(item) {
  let data = item._cachedVotingData;
  if (!data) {
    item._cachedVotingData = data = getVotingData(item);
  }
  return data;
}


class YHeatMapInner extends pieChartMapMixin(YMapInner) {

  static getMapConfig() {
    return Object.assign(YMapInner.getMapConfig(), {
      behaviors: [
        'drag',
        'dblClickZoom',
        'rightMouseButtonMagnifier',
        'multiTouch',
      ]
    });
  }

  getChartData(item) {
    if (!item.extra || !item.extra.group_size)
      return {total: 0};

    const vd = votingData(item),
      total = vd.total;

    const measure = item.extra.measure.value || item.extra.measure;
    const leadEl = (
      <div>
        <span className="textLevel">{gettext('Level of satisfaction')}</span>
        <span style={{color: vd.colorLevel}}
              className="percentage">&nbsp;{vd.percentage}<small>%</small>
        </span>
        <div className="subtitleList">
          <p>{measure}</p>
        </div>
      </div>
    );

    const summary = (<p className="voted">
      <span>{gettext('voted')}: </span>{vd.voted}
    </p>);

    return {total, leadEl, charts: vd.data, summary};
  }

  getPinColor(item) {
    if (votingData(item).total)
      return votingData(item).pinColor;
    else
      return this._getPinColor(item);
  }

  getGroupColor(item) {
    if (votingData(item).total) {
      const items = votingData(item),
        {groupColor, groupBorderColor, regionColor} = items;
      return {groupColor, groupBorderColor, regionColor};
    } else
      return this._getGroupColor(item);
  }
}


const YMapWrapped = withYMaps(YHeatMapInner, true, ['templateLayoutFactory']);


export default class YMap extends React.Component {
  render() {
    return (
      <YMaps>
        <YMapWrapped {...this.props} getMapConfig={YHeatMapInner.getMapConfig}/>
      </YMaps>
    );
  }
}

import React, { Component } from 'react';
import ListItemMixin from 'components/BaseEntities/ListItemMixin';


// TypicalInitiativeListItem Component

export default class TypicalInitiativeList extends Component {
  render() {
    const { items, actions, loading, descriptions, meta, data_mart } = this.props;
    let entities_class = "entities list-items";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <div className={entities_class}>
        {items.map(
          (child, i) =>
          <TypicalInitiativeListItem
            key={i}
            data={child}
            actions={actions}
            descriptions={descriptions}
            pk={data_mart.pk}
            next_url={data_mart.next_url}
          />
        )}
      </div>
    );
  }
}

class TypicalInitiativeListItem extends ListItemMixin(Component) {

  getItemContent(url, title, infoUrl, data, descriptionBaloon){
    return(
      <div className="wrap-list-item"
           onClickCapture={e => { ::this.handleMouseClick(e); } }>
        <div className="row">
          <div className="col-md-12">
            <h4>
              <a href={url}>
                {title}
              </a>
              <a href={infoUrl} title={data.entity_name}>
                <i className="fa fa-info-circle" data-toggle="tooltip" data-placement="top" title={gettext('Click for read initiative type detail')} />
              </a>
            </h4>
            {descriptionBaloon}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { data, descriptions, position, meta, pk, next_url } = this.props,
          groupSize = data.extra.group_size || 0,
          infoUrl = data.extra && data.extra.url ? data.extra.url : data.entity_url,
          url = next_url ? `${next_url}?typical_initiative_id=${data.id}` : infoUrl;

    let groupDigit = "";
    if (groupSize) {
      groupDigit = (
        <div className="ex-pack">
          <span className="ex-digit">{groupSize}</span>
          <div><div><div></div></div></div>
        </div>
      );
    }

    let characteristics = data.short_characteristics || [],
        marks = data.short_marks || [];

    // let related_data_marts = [];
    if (descriptions[data.id]) {
      characteristics = descriptions[data.id].characteristics || [];
      marks = descriptions[data.id].marks || [];
      // related_data_marts = descriptions[data.id].marks || [];
    }

    const className = "ex-catalog-item list-item" + (groupSize ? " ex-catalog-item-variants" : "") +
        (descriptions.opened[data.id] ? " ex-state-description" : "");

    const exAttrs = this.getExAttrs(data, characteristics),
          exTags = this.getExTags(marks),
          descriptionBaloon = this.getDescriptionBaloon(data, characteristics, marks, descriptions, exAttrs, exTags) || "",
          title = groupSize && !meta.alike ? data.extra.group_name : data.entity_name,
          itemContent = this.getItemContent(url, title, infoUrl, data, descriptionBaloon);

    return (
      <div className={className}
         onMouseOver={e => this.handleMouseOver(e)}
         onMouseOut={e => this.handleMouseOut(e)}
         style={{minHeight: this.state.minHeight}}>
        {groupDigit}
        {itemContent}
      </div>
    );
  }
}

import React from 'react';
import marked from 'marked';
import { Doughnut, Pie } from "react-chartjs-2";
import EntityRatingMixin from "./EntityRatingMixin";


const SIZE_PIE_CHART = 130,
      SIZE_DOUGHNUT_CHART = 100,
      CHART_OPTIONS = {
        legend: { display: false },
        tooltips: { enabled: false },
        hover: { mode: null },
        animation: { duration: 0 },
        elements: {
          arc: { borderWidth: 0 }
        }
      };

const EntityRatingListItemMixin = Base => class extends EntityRatingMixin(Base) {

  getDescriptionBaloon(characteristics, marks, itemData){
    const {annotations, created_at, author} = itemData;
    if (characteristics.length) {
      return(
        <div className="ex-description-wrapper">
          {itemData.total  ?
          <div className="chartPieListContainer">
            <span style={{fontSize:"18px"}}>{gettext('Level of satisfaction')}</span>
            <span style={{color: itemData.colorLevel,fontSize:"22px"}}> {itemData.percentage}<small style={{color: itemData.colorLevel}}>%</small></span>
            {itemData.measure &&
              <div className="subtitleList">
                <p>{itemData.measure}</p>
              </div>
            }
            <div className="chartPieList d-flex align-items-center">
              <div style={{marginLeft:"-80px"}}>
                <Pie data={itemData.chartData} options={CHART_OPTIONS} height={SIZE_PIE_CHART}/>
              </div>
              <div className="chartPieListLegend">
                {itemData.chartData.labels.map((legend,i)=>
                  <p key={i}>
                    <span className="chartPieLegendColor" style={{background:itemData.chartData.datasets[0].backgroundColor[i]}}/>
                    <span className="chartPieLegendText">{legend}: </span><span>{Number((itemData.chartData.datasets[0].data[i]/(itemData.total/100)).toFixed(2))}<small>%</small></span>
                  </p>
                  )}
                {itemData.measures_count ?
                  <p style={{paddingTop:"10px"}}>
                    <span style={{fontWeight:"bold"}}>{gettext('voted')}: </span>
                    {Math.round(itemData.total/itemData.measures_count)}
                  </p>
                  :
                  <p style={{paddingTop:"10px"}}>
                    <span style={{fontWeight:"bold"}}>Оценок: </span>
                    {itemData.total}
                  </p>
                }
              </div>
            </div>
          </div>
          : ""}
          <ul className="ex-attrs p-0">
            {characteristics.map(
              (child, i) =>
                <li data-path={child.path} key={i}
                  data-view-class={child.view_class.join(" ")}>
                  <strong>{child.name}:&nbsp;</strong>
                  {child.values.join("; ")}
                </li>
            )}
            {itemData.address ?
              <li><strong>{gettext('address')}: </strong>{itemData.address}</li>
            :''}
            {Object.keys(annotations).length !== 0 &&
              <li className="annotation">
                {Object.keys(annotations).map(
                  (key, i) =>
                    <div key={i}>
                      <strong>{annotations[key].name}:&nbsp;</strong>
                      {annotations[key].value instanceof Array ?
                        annotations[key].value.map((val, key) => <span key={key}>{val};&nbsp;</span>)
                      :
                        <span key={key}>{annotations[key].value}</span>
                      }
                    </div>
                )}
              </li>
            }
          </ul>
          <ul className="ex-tags">
            {marks.map(
              (child, i) =>
                <li className="ex-tag"
                    key={i}
                    data-name={child.name}
                    data-path={child.path}
                    data-view-class={child.view_class.join(" ")}>
                  <i className="fa fa-tag"/>&nbsp;
                  {child.values.join(", ")}
                </li>
            )}
          </ul>
          {created_at ?
              <div className="particular-initiative-extra">
                <strong><i className="fa fa-calendar" aria-hidden="true"/></strong>&nbsp;
                {created_at.getDate() > 9 ? created_at.getDate() : '0'+created_at.getDate()}.
                {created_at.getMonth() > 9 ? created_at.getMonth() :'0'+(created_at.getMonth())}.
                {created_at.getFullYear()}
                <strong className="second"><i className="fa fa-user-o" aria-hidden="true"/></strong>&nbsp;
                  {author}
              </div>
            : null
          }
        </div>
      );
    }
  }

  getShortDescription(shortCharacteristics){
    if (shortCharacteristics.length) {
      return(
        <div className="short-description">
          <ul className="ex-attrs">
            {shortCharacteristics.map(
              (child, i) =>
                <li data-path={child.path} key={i}
                    data-view-class={child.view_class.join(" ")}>
                  <strong>{child.name}:&nbsp;</strong>
                  {child.values.join("; ")}
                </li>
            )}
          </ul>
        </div>
      )
    }
  }

  getItemBlock(url, data, title, descriptions, descriptionBaloon, shortDescription, exAttrs=null){
    return (
      <div className="col-md-9">
        <a href={url}>
          <h4>{title}</h4>
        </a>
        {/*{exAttrs}*/}
        {descriptions.opened[data.id] ? descriptionBaloon : shortDescription}
      </div>
    )
  }

  getItemContent(itemData, marks, url, data, itemBlock, exRibbons = null){
    return (
      <div className="wrap-list-item ex-catalog-raiting-item-block"
           onClickCapture={e => { ::this.handleMouseClick(e); } }>
        <div className="row">

          {exRibbons ?
            <ul className="ex-ribbons">
              {marks.map(
                (child, i) =>
                  <li className="ex-wrap-ribbon"
                      key={i}
                      data-name={child.name}
                      data-path={child.path}
                      data-view-class={child.view_class.join(" ")}>
                    <div className="ex-ribbon">{child.values.join(", ")}</div>
                  </li>
              )}
            </ul> : null
          }

          <div className="col-md-3">
            {itemData.total ?
              <div className="chartDoughnutContainer d-flex justify-content-center align-items-center">
                <Doughnut data={itemData.chartData} options={CHART_OPTIONS} height={SIZE_DOUGHNUT_CHART}/>
                <span style={{color: itemData.colorLevel}}>{itemData.percentage}
                  <small style={{color: itemData.colorLevel}}>%</small>
                </span>
              </div> : null
            }
            <a href={url}>
              <div className="ex-media" dangerouslySetInnerHTML={{__html: marked(data.media, {sanitize: false})}}/>
            </a>
          </div>

          {itemBlock}
        </div>
      </div>
    )
  }
};

export default EntityRatingListItemMixin

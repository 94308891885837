import React, { Component } from 'react';
import ListItemMixin from 'components/BaseEntities/ListItemMixin';
import EntityRatingListItemMixin from "./EntityRatingListItemMixin";


// Container

export default class ParticularEstablishmentList extends Component {

  componentDidMount() {
    const not_group = this.props.data_mart.not_group || null
    not_group && not_group === "true" && this.props.actions.setEntitiesNotGroup(true);
  };

  render() {
    const { items, actions, loading, descriptions, meta } = this.props;
    let entities_class = "entities list-items";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <div className={entities_class}>
        {items.map(
          (child, i) =>
          <ParticularEstablishmentListItem
              key={i}
              data={child}
              actions={actions}
              loading={loading}
              descriptions={descriptions}
              position={i}
              meta={meta}
          />
        )}
      </div>
    );
  }
}

// Element

class ParticularEstablishmentListItem extends EntityRatingListItemMixin(ListItemMixin(Component)) {

  render() {
    const { data, meta, descriptions } = this.props,
          url = data.extra && data.extra.url ? data.extra.url : data.entity_url,
          groupSize = data.extra.group_size || 0;

    let groupDigit = "";
    if (groupSize) {
      groupDigit = (
        <div className="ex-pack">
          <span className="ex-digit">{groupSize}</span>
          <div><div><div></div></div></div>
        </div>
      );
    }

    let characteristics = data.short_characteristics || [],
        shortCharacteristics = characteristics,
        marks = data.short_marks || [];

    // let related_data_marts = [];
    if (descriptions[data.id]) {
      characteristics = descriptions[data.id].characteristics || [];
      marks = descriptions[data.id].marks || [];
      // related_data_marts = descriptions[data.id].marks || [];
    }

    const className = "ex-catalog-item list-item" + (groupSize ? " ex-catalog-item-variants" : "") +
        (descriptions.opened[data.id] ? " ex-state-description" : "");

    const shortDescription = this.getShortDescription(shortCharacteristics) || "",
          itemData = this.votingData(data),
          descriptionBaloon = this.getDescriptionBaloon(characteristics, marks, itemData),
          title = groupSize && !meta.alike ? data.extra.group_name : data.entity_name,
          itemBlock = this.getItemBlock(url, data, title, descriptions, descriptionBaloon, shortDescription),
          itemContent = this.getItemContent(itemData, marks, url, data, itemBlock);

    return (
      <div className={className}
         onMouseOver={e => this.handleMouseOver(e)}
         onMouseOut={e => this.handleMouseOut(e)}
         style={{minHeight: this.state.minHeight}}>
        {groupDigit}
        {itemContent}
      </div>
    );
  }
}
